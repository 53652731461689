<template>
	<Page layout="sidebar">
		<Sidebar>
			<Stack width="80%" direction="column" align="left" space="3">
				<Link url="/havelock" @click.native="scrollMeTo('home')">
					<Stack space="2">
						<div class="logo">
							<Heading size="2" margin="none" whiteout>H</Heading>
						</div>
						<Heading size="4" uppercase>Havelock</Heading>
					</Stack>
				</Link>
				<Stack width="100%" direction="column" align="left" space="3">
					<Link text="About me" url="/havelock" @click.native="scrollMeTo('about')" />
					<Divider size="small" />
					<Link text="Skills" url="/havelock" @click.native="scrollMeTo('skills')" />
					<Divider size="small" />
					<Link text="Education" url="/havelock" @click.native="scrollMeTo('education')" />
					<Divider size="small" />
					<Link text="Employment" url="/havelock" @click.native="scrollMeTo('employment')" />
					<Divider size="small" />
					<Link text="Portfolio" url="/havelock" @click.native="scrollMeTo('portfolio')" />
					<Divider size="small" />
					<Link text="Blog" url="/havelock" @click.native="scrollMeTo('blog')" />
					<Divider size="small" />
					<Link text="Contact" url="/havelock" @click.native="scrollMeTo('contact')" />
				</Stack>
				<Stack>
					<Icon icon="thumb_up" size="medium" color="primary" />
					<Icon icon="favorite_outline" size="medium" color="primary" />
					<Icon icon="email" size="medium" color="primary" />
				</Stack>
			</Stack>
		</Sidebar>
		<Body>
			<Stack width="100%" justify="center" visibility="show hide@md">
					<Stack justify="spaceBetween" width="98%" space="2">
						<Link url="/havelock" @click.native="scrollMeTo('home')">
							<Stack space="2">
								<div class="logo">
									<Heading size="2" margin="none" whiteout>H</Heading>
								</div>
								<Heading size="4" uppercase>Havelock</Heading>
							</Stack>
						</Link>
						<Link @click.native="setOpenMenu()" display="inline" url="">
							<Icon icon="menu" color="dark" size="large" v-if="!isOpenMenu" />
							<Icon icon="menu_open" color="dark" size="large" v-if="isOpenMenu" />
						</Link>
					</Stack>
					<Dropdown :open="isOpenMenu">
						<Stack direction="column" space="2">
							<Link text="About me" url="/havelock" color="light" @click.native="scrollMeTo('about')" />
							<Link text="Skills" url="/havelock" color="light" @click.native="scrollMeTo('skills')" />
							<Link text="Education" url="/havelock" color="light" @click.native="scrollMeTo('education')" />
							<Link text="Employment" url="/havelock" color="light" @click.native="scrollMeTo('employment')" />
							<Link text="Portfolio" url="/havelock" color="light" @click.native="scrollMeTo('portfolio')" />
							<Link text="Blog" url="/havelock" color="light" @click.native="scrollMeTo('blog')" />
							<Link text="Contact" url="/havelock" color="light" @click.native="scrollMeTo('contact')" />
						</Stack>
					</Dropdown>
			</Stack>
			<div ref="home" class="anchor"></div>
			<Section color="primary" size="xsmall">
				<Grid>
					<GridContainer size="6" align="middle">
						<Stack direction="column" align="left" space="1">
							<Heading size="2" uppercase>Hello, I'm</Heading>
							<Heading size="1" uppercase whiteout>Carter Havelock</Heading>
							<Heading size="5" uppercase>Artist / Muralist</Heading>
						</Stack>
					</GridContainer>
					<GridContainer size="6">
						<Images width="100%" height="100vh" image="theme-hv/intro.jpg" />
					</GridContainer>
				</Grid>
			</Section>
			<div ref="about" class="anchor"></div>
			<Section>
				<Grid>
					<GridContainer size="5">
						<Images width="100%" height="500px" image="theme-hv/aboutme.jpg" />
					</GridContainer>
					<GridContainer size="1"></GridContainer>
					<GridContainer size="4">
						<Heading size="2" uppercase>About me</Heading>
						<Paragraph>Vivamus suscipit volutpat nisl non vulputate. Praesent fermentum maximus nisl, eu tincidunt libero vulputate nec. Proin ante nulla, auctor eu risus ac, mollis ultrices magna.</Paragraph>
						<Table>
							<TableRow>
								<TableCell>
									<Paragraph micro>Name:</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph micro>Carter Havelock</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph micro>Email:</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph micro>carter.havelock@gmail.com</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph micro>Phone:</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph micro>1 (234) 567-8901</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph micro>Date of Birth:</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph micro>23 February 1987</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
						<Button label="Download Resume" color="dark" />
					</GridContainer>
				</Grid>
			</Section>
			<div ref="skills" class="anchor"></div>
			<Section color="light">
				<Heading size="2" uppercase>Skills</Heading>
				<Grid>
					<GridContainer size="4">
						<Stack space="2" align="top">
							<Icon icon="table_chart" size="large" color="primary" />
							<Stack direction="column" align="left">
								<Heading size="5" uppercase>Large-Scale Murals</Heading>
								<Paragraph micro>Praesent congue tincidunt imperdiet. Proin aliquet tortor lacus, et finibus purus eleifend nec. Ut venenatis ex sit amet egestas lobortis.</Paragraph>
							</Stack>
						</Stack>
					</GridContainer>
					<GridContainer size="4">
						<Stack space="2" align="top">
							<Icon icon="emoji_events" size="large" color="primary" />
							<Stack direction="column" align="left">
								<Heading size="5" uppercase>Composition</Heading>
								<Paragraph micro>Praesent congue tincidunt imperdiet. Proin aliquet tortor lacus, et finibus purus eleifend nec. Ut venenatis ex sit amet egestas lobortis.</Paragraph>
							</Stack>
						</Stack>
					</GridContainer>
					<GridContainer size="4">
						<Stack space="2" align="top">
							<Icon icon="explore" size="large" color="primary" />
							<Stack direction="column" align="left">
								<Heading size="5" uppercase>Texture Painting</Heading>
								<Paragraph micro>Praesent congue tincidunt imperdiet. Proin aliquet tortor lacus, et finibus purus eleifend nec. Ut venenatis ex sit amet egestas lobortis.</Paragraph>
							</Stack>
						</Stack>
					</GridContainer>
					<GridContainer size="4">
						<Stack space="2" align="top">
							<Icon icon="content_cut" size="large" color="primary" />
							<Stack direction="column" align="left">
								<Heading size="5" uppercase>Color Theory</Heading>
								<Paragraph micro>Praesent congue tincidunt imperdiet. Proin aliquet tortor lacus, et finibus purus eleifend nec. Ut venenatis ex sit amet egestas lobortis.</Paragraph>
							</Stack>
						</Stack>
					</GridContainer>
					<GridContainer size="4">
						<Stack space="2" align="top">
							<Icon icon="settings_system_daydream" size="large" color="primary" />
							<Stack direction="column" align="left">
								<Heading size="5" uppercase>Realistic Drawing</Heading>
								<Paragraph micro>Praesent congue tincidunt imperdiet. Proin aliquet tortor lacus, et finibus purus eleifend nec. Ut venenatis ex sit amet egestas lobortis.</Paragraph>
							</Stack>
						</Stack>
					</GridContainer>
					<GridContainer size="4">
						<Stack space="2" align="top">
							<Icon icon="auto_fix_high" size="large" color="primary" />
							<Stack direction="column" align="left">
								<Heading size="5" uppercase>Sculpting</Heading>
								<Paragraph micro>Praesent congue tincidunt imperdiet. Proin aliquet tortor lacus, et finibus purus eleifend nec. Ut venenatis ex sit amet egestas lobortis.</Paragraph>
							</Stack>
						</Stack>
					</GridContainer>
				</Grid>
			</Section>
			<Section color="secondary" size="small">
				<Grid>
					<GridContainer size="3">
						<Stack direction="column" space="1">
							<Heading size="2" uppercase>2530</Heading>
							<Paragraph micro>Hours on Murals</Paragraph>
						</Stack>
					</GridContainer>
					<GridContainer size="3">
						<Stack direction="column" space="1">
							<Heading size="2" uppercase>3200</Heading>
							<Paragraph micro>Hours on Canvas</Paragraph>
						</Stack>
					</GridContainer>
					<GridContainer size="3">
						<Stack direction="column" space="1">
							<Heading size="2" uppercase>2830</Heading>
							<Paragraph micro>Hours on Drawing</Paragraph>
						</Stack>
					</GridContainer>
					<GridContainer size="3">
						<Stack direction="column" space="1">
							<Heading size="2" uppercase>1035</Heading>
							<Paragraph micro>Hours on Pottery</Paragraph>
						</Stack>
					</GridContainer>
				</Grid>
			</Section>
			<div ref="education" class="anchor"></div>
			<Section>
				<Grid>
					<GridContainer size="7">
						<Heading size="2" uppercase>Education</Heading>
						<Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum faucibus urna vitae elit accumsan placerat. Morbi ut mauris fringilla, posuere erat in, pretium erat.</Paragraph>
						<Divider />
						<Stack direction="column row@sm">
							<Stack direction="column" align="left" space="3">
								<Stack space="3" align="top">
									<Heading size="2" uppercase color="secondary">01.</Heading>
									<Stack direction="column" align="left">
										<Heading size="5" uppercase>University of Design</Heading>
										<Paragraph micro>Bachelors of Arts</Paragraph>
										<Paragraph micro>2012 - 2013</Paragraph>
									</Stack>
								</Stack>
								<Stack space="3" align="top">
									<Heading size="2" uppercase color="secondary">02.</Heading>
									<Stack direction="column" align="left">
										<Heading size="5" uppercase>Boston University</Heading>
										<Paragraph micro>Visual Arts &amp; Design</Paragraph>
										<Paragraph micro>2011 - 2013</Paragraph>
									</Stack>
								</Stack>
							</Stack>
							<Stack direction="column" align="left" space="3">
								<Stack space="3" align="top">
									<Heading size="2" uppercase color="secondary">03.</Heading>
									<Stack direction="column" align="left">
										<Heading size="5" uppercase>Boston University</Heading>
										<Paragraph micro>Degree of Design</Paragraph>
										<Paragraph micro>2009 - 2011</Paragraph>
									</Stack>
								</Stack>
								<Stack space="3" align="top">
									<Heading size="2" uppercase color="secondary">04.</Heading>
									<Stack direction="column" align="left">
										<Heading size="5" uppercase>University of Design</Heading>
										<Paragraph micro>Degree of Graphic Arts</Paragraph>
										<Paragraph micro>2007 - 2009</Paragraph>
									</Stack>
								</Stack>
							</Stack>
						</Stack>
						<Divider />
					</GridContainer>
					<GridContainer size="1"></GridContainer>
					<GridContainer size="4">
						<Images width="100%" height="500px" image="theme-hv/education.jpg" />
					</GridContainer>
				</Grid>
			</Section>
			<div ref="employment" class="anchor"></div>
			<Section color="light">
				<Heading size="2" uppercase>Employment</Heading>
				<Grid>
					<GridContainer size="3">
						<Stack direction="column" align="left">
							<Heading size="5" color="primary" uppercase>Panara Media</Heading>
							<Heading size="5" uppercase>Art Director</Heading>
							<Paragraph>2015 - 2013</Paragraph>
							<Paragraph micro>Morbi quis posuere neque, nec sollicitudin sem. Nulla sem ante, condimentum ut vestibulum ac, ornare a felis.</Paragraph>
						</Stack>
					</GridContainer>
					<GridContainer size="3">
						<Stack direction="column" align="left">
							<Heading size="5" color="primary" uppercase>Linkstructure Web</Heading>
							<Heading size="5" uppercase>Project Manager</Heading>
							<Paragraph>2013 - 2012</Paragraph>
							<Paragraph micro>Morbi quis posuere neque, nec sollicitudin sem. Nulla sem ante, condimentum ut vestibulum ac, ornare a felis.</Paragraph>
						</Stack>
					</GridContainer>
					<GridContainer size="3">
						<Stack direction="column" align="left">
							<Heading size="5" color="primary" uppercase>Matrix Media</Heading>
							<Heading size="5" uppercase>Senior Artist</Heading>
							<Paragraph>2012 - 2011</Paragraph>
							<Paragraph micro>Morbi quis posuere neque, nec sollicitudin sem. Nulla sem ante, condimentum ut vestibulum ac, ornare a felis.</Paragraph>
						</Stack>
					</GridContainer>
					<GridContainer size="3">
						<Stack direction="column" align="left">
							<Heading size="5" color="primary" uppercase>Creatika Agency</Heading>
							<Heading size="5" uppercase>Graphic Designer</Heading>
							<Paragraph>2011 - 2010</Paragraph>
							<Paragraph micro>Morbi quis posuere neque, nec sollicitudin sem. Nulla sem ante, condimentum ut vestibulum ac, ornare a felis.</Paragraph>
						</Stack>
					</GridContainer>
				</Grid>
			</Section>
			<Section color="primary">
				<Grid>
					<GridContainer size="5">
						<Images width="100%" height="300px" image="theme-hv/awards.jpg" />
					</GridContainer>
					<GridContainer size="1">
					</GridContainer>
					<GridContainer size="6">
						<Heading size="4" whiteout uppercase>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum faucibus urna vitae elit accumsan placerat.</Heading>
						<Divider />
						<Stack direction="column row@md" space="3">
							<Stack space="3" align="top">
								<Heading size="2" uppercase>01.</Heading>
								<Heading size="5" uppercase whiteout>International Design Award</Heading>
							</Stack>
							<Stack space="3" align="top">
								<Heading size="2" uppercase>02.</Heading>
								<Heading size="5" uppercase whiteout>European Design Award</Heading>
							</Stack>
						</Stack>
					</GridContainer>
				</Grid>
			</Section>
			<div ref="portfolio" class="anchor"></div>
			<Section>
				<Heading size="2" uppercase>Portfolio</Heading>
				<Grid>
					<GridContainer size="12 4@md">
					</GridContainer>
					<GridContainer size="12 6@sm 4@md">
						<Stack direction="column" space="2">
							<Images width="100%" image="theme-hv/project4.jpg" />
							<Images width="100%" image="theme-hv/project6.jpg" />
							<Images width="100%" image="theme-hv/project3.jpg" />
							<Images width="100%" image="theme-hv/project8.jpg" />
						</Stack>
					</GridContainer>
					<GridContainer size="12 6@sm 4@md">
						<Stack direction="column" space="2">
							<Images width="100%" image="theme-hv/project5.jpg" />
							<Images width="100%" image="theme-hv/project2.jpg" />
							<Images width="100%" image="theme-hv/project1.jpg" />
							<Images width="100%" image="theme-hv/project7.jpg" />
						</Stack>
					</GridContainer>
				</Grid>
			</Section>
			<div ref="blog" class="anchor"></div>
			<Section>
				<Heading size="2" uppercase>Recent Blog</Heading>
				<Grid>
					<GridContainer size="12 4@md">
						<Stack direction="column row@sm column@md" space="2">
							<Card size="xlarge" color="secondary">
								<CardSection>
									<Link url="/havelock" display="inline">
										<Stack direction="column" align="left">
											<Heading size="4" uppercase>A portrait is not made in the camera but on either side of it.</Heading>
											<Heading size="5" uppercase whiteout>April 11, 2016</Heading>
											<Heading size="5" uppercase>Posted by Carter</Heading>
										</Stack>
									</Link>
								</CardSection>
							</Card>
							<Card size="xlarge">
								<CardSection>
									<Link url="/havelock" display="inline">
										<Stack direction="column" align="left">
											<Heading size="4" uppercase>You don't take a photograph. You ask quietly to borrow it.</Heading>
											<Heading size="5" uppercase color="primary">April 11, 2016</Heading>
											<Heading size="5" uppercase>Posted by Carter</Heading>
										</Stack>
									</Link>
								</CardSection>
							</Card>
						</Stack>
					</GridContainer>
					<GridContainer size="12 4@md">
						<Stack direction="column row@sm column@md" space="2">
							<Card size="xlarge">
								<CardSection>
									<Link url="/havelock" display="inline">
										<Stack direction="column" align="left">
											<Heading size="4" uppercase>You don't take a photograph. You ask quietly to borrow it.</Heading>
											<Heading size="5" uppercase color="primary">April 11, 2016</Heading>
											<Heading size="5" uppercase>Posted by Carter</Heading>
										</Stack>
									</Link>
								</CardSection>
							</Card>
							<Card size="xlarge" color="primary">
								<CardSection>
									<Link url="/havelock" display="inline">
										<Stack direction="column" align="left">
											<Heading size="4" uppercase whiteout>A portrait is not made in the camera but on either side of it.</Heading>
											<Heading size="5" uppercase>April 11, 2016</Heading>
											<Heading size="5" uppercase whiteout>Posted by Carter</Heading>
										</Stack>
									</Link>
								</CardSection>
							</Card>
						</Stack>
					</GridContainer>
					<GridContainer size="12 4@md">
						<Stack direction="column row@sm column@md" space="2">
							<Card size="xlarge" color="light">
								<CardSection>
									<Link url="/havelock" display="inline">
										<Stack direction="column" align="left">
											<Heading size="4" uppercase>A portrait is not made in the camera but on either side of it.</Heading>
											<Heading size="5" uppercase color="primary">April 11, 2016</Heading>
											<Heading size="5" uppercase>Posted by Carter</Heading>
										</Stack>
										</Link>
								</CardSection>
							</Card>
							<Card size="xlarge">
								<CardSection>
									<Link url="/havelock" display="inline">
										<Stack direction="column" align="left">
											<Heading size="4" uppercase>You don't take a photograph. You ask quietly to borrow it.</Heading>
											<Heading size="5" uppercase color="primary">April 11, 2016</Heading>
											<Heading size="5" uppercase>Posted by Carter</Heading>
										</Stack>
									</Link>
								</CardSection>
							</Card>
						</Stack>
					</GridContainer>
				</Grid>
			</Section>
			<div ref="contact" class="anchor"></div>
			<Section>
				<Grid>
					<GridContainer size="6">
						<Heading size="2" uppercase>Contact</Heading>
						<Stack direction="column row@md" space="3">
							<Stack space="2" align="center">
								<Icon icon="room" size="large" color="primary" />
								<Stack direction="column" align="left">
									<Heading size="5" uppercase>1234 Street Rd.</Heading>
									<Paragraph micro>Las Vegas NV 89014</Paragraph>
								</Stack>
							</Stack>
							<Stack space="2" align="center">
								<Icon icon="content_paste" size="large" color="primary" />
								<Stack direction="column" align="left">
									<Heading size="5" uppercase>General Inquiries</Heading>
									<Paragraph micro>sales@domain.com</Paragraph>
								</Stack>
							</Stack>
						</Stack>
						<Form>
							<FormContainer size="12">
								<Input label="Your Name" />
							</FormContainer>
							<FormContainer size="12">
								<Input label="Your Email" />
							</FormContainer>
							<FormContainer size="12">
								<Input label="What are you interested in?" />
							</FormContainer>
							<FormContainer size="12">
								<Textarea label="Your Message" />
							</FormContainer>
						</Form>
						<Button label="Submit" color="dark" />
					</GridContainer>
					<GridContainer size="1"></GridContainer>
					<GridContainer size="5">
						<Images width="100%" height="650px" image="theme-hv/contact.jpg" />
					</GridContainer>
				</Grid>
			</Section>
		</Body>
		<Footer>
			<Section size="small">
				<Stack direction="columnReverse row@sm" justify="left spaceBetween@sm" align="center">
					
					<Stack justify="center left@sm">
						<Icon icon="thumb_up" size="medium" color="primary" />
						<Icon icon="favorite_outline" size="medium" color="primary" />
						<Icon icon="email" size="medium" color="primary" />
						<Icon icon="phone" size="medium" color="primary" />
						<Icon icon="contactless" size="medium" color="primary" />
					</Stack>
					
					<Stack space="2" justify="center right@sm">
						<div class="logo">
							<Heading size="2" margin="none" whiteout>H</Heading>
						</div>
						<Heading size="4" uppercase>Havelock</Heading>
					</Stack>
				</Stack>
				<Divider size="small" />
				<Paragraph micro align="center">© 2022 Havelock is proudly powered by Anna Robbins.</Paragraph>
			</Section>
		</Footer>
	</Page>
</template>

<script>
export default {
	props: {
		theme: String
	},
  data() {
    return {
			isOpen: false,
			isOpenMenu: false,
		}
  },
  mounted() {
    let bodyElement = document.documentElement;
    localStorage.setItem("theme", this.theme);
    bodyElement.setAttribute('theme', this.theme);
  },
	methods: {
		scrollMeTo(refName) {
			var element = this.$refs[refName];
			var top = element.offsetTop;
			window.scrollTo({
				top: top,
				behavior: 'smooth'
			});
		},
		openModal: function(id) {
			document.getElementById(id).classList.add("modal--open");
			document.body.classList.add("modal--open");
		},
		setOpenMenu(){
			this.isOpenMenu = !this.isOpenMenu;
		},
    setCloseMenu() {
      this.isOpenMenu = false
    },
	}
}

</script>

<style lang="scss">
.logo{
	background: #ef106e;
	padding: 0 5px;
	.heading-02{
		margin: 0;
	}
}
</style>
